var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-masterdata", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "sidebar", attrs: { cols: "3" } },
            [
              _c(
                "v-list",
                { attrs: { nav: "" } },
                [
                  _c("v-subheader", [_vm._v("Locatie")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-EedType" } } },
                        [_c("v-list-item-title", [_vm._v("EedType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: { text: "", to: { name: "MD-LocationType" } },
                        },
                        [_c("v-list-item-title", [_vm._v("LocationType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Nta8800Function" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Nta8800Function (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-StartingPointAmbition" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("StartingPointAmbition"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-StartingPointCondition" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("StartingPointCondition"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-StartingPointStrategy" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("StartingPointStrategy"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Adressen")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-AddressSourceType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("AddressSourceType"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-AddressType" } } },
                        [_c("v-list-item-title", [_vm._v("AddressType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-AddressableObjectRoofType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("AddressableObjectRoofType"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-AddressableObjectSubTypeHor" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("AddressableObjectSubTypeHor"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-AddressableObjectSubTypeVer" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("AddressableObjectSubTypeVer"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-AddressableObjectType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("AddressableObjectType"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Bouwkundig")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-ConstructionBoundaryType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("ConstructionBoundaryType"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-ConstructionInsulationCategory" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("ConstructionInsulationCategory"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-ConstructionKind" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("ConstructionKind")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-ConstructionSourceType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("ConstructionSourceType"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Installatie")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationCollaborationType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationCollaborationType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationControlType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationControlType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationDeliveryType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationDeliveryType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationDetailType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationDetailType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationGroup" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationGroup"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationInputSource" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationInputSource (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationProductType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationProductType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationSubDetailType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationSubDetailType (met id)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-InstallationType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("InstallationType (met key)"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Party")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-PartyRole" } } },
                        [_c("v-list-item-title", [_vm._v("PartyRole")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-PartySourceType" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("PartySourceType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-PartyType" } } },
                        [_c("v-list-item-title", [_vm._v("PartyType")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Maatregelen")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-AppliedGroup" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("AppliedGroup")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-AppliedStatus" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("AppliedStatus")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-BuildingFunction" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("BuildingFunction (met id en extra velden)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-ConditionType" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("ConditionType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-CostSourceType" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("CostSourceType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-ImCategory" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("ImCategory + Ecm.ImSubCategory"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-Ecm-Kind" } } },
                        [_c("v-list-item-title", [_vm._v("Kind")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-NlsfbCategory" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("NlSfbCategory (id ipv key)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: { text: "", to: { name: "MD-Ecm-NlSfbCode" } },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("NlSfbCode (met id en extra velden)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-Ecm-StatusSourceType" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("StatusSourceType")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("Energie")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-EU-EdsObjectType" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("EdsObjectType")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: { text: "", to: { name: "MD-EU-EnergyType" } },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("EnergyType (met id en extra velden)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-EU-EuPrecisionTypes" },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v("EuPrecisionTypes")])],
                        1
                      ),
                      _c(
                        "v-list-item",
                        {
                          attrs: {
                            text: "",
                            to: { name: "MD-EU-RegisterType" },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("RegisterType (met id en extra velden)"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-EU-UnitType" } } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("UnitType (met id en extra velden)"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-subheader", [_vm._v("WEij")]),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: { text: "", to: { name: "MD-Weii-Category" } },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v("WEii Category + WEii SubCategory"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { text: "", to: { name: "MD-Weii-Decree" } } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("WEii Decree (met Key)"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", [_c("router-view")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }